<template>




  <div :style="foreground">
    <Header />
  <v-card hover class="card">
    <v-list-item>
      <v-list-item-avatar tile size="300" color="blue">
        Bild
<!--        <v-img
            width="300px"
            :src="require('../../assets/aboutUs/sabrina/sabrina1.jpg')"
        ></v-img>-->
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1 primary--text"
        >Simona Badilatti</v-list-item-title
        >
        <v-list-item-title class="title mb-1"
        >Papperlapapp</v-list-item-title
        >
        <div style="white-space: pre-line;">
          Beschreibung
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
    <v-card hover class="card" :to="'/AboutUs/Tania'">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1 primary--text"
          >Weiss Nicht</v-list-item-title
          >
          <v-list-item-title class="title mb-1"
          >Blablabla</v-list-item-title
          >
          <div style="white-space: pre-line;">
            Beschreibung
          </div>
        </v-list-item-content>
        <v-list-item-avatar tile size="300" color="blue">
          Bild
<!--          <v-img
              width="300px"
              :src="require('../../assets/tania1.jpg')"
          ></v-img>-->
        </v-list-item-avatar>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import Header from "../Header/Header";

export default {
  name: "Trauzeugen",
  components: {
    Header,
  },
  data: function() {
    return {
      foreground: {
        maxWidth: "720px" /* just for example */,
        margin: "0 auto",
        float: "auto",
        width: "500",
        height: "auto",
        /*backdropFilter: "blur(5px)"*/
      }
    };
}
}
</script>

<style scoped>
.card {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>